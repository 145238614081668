import { useEffect } from 'react';

export const keyCodes = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
};

const useOnKeypress = (key, handler) => {
  useEffect(() => {
    const listener = event => {
      if (event.keyCode === key) {
        handler(event);
      }
    };
    window.document.addEventListener('keydown', listener);

    return () => {
      window.document.removeEventListener('keydown', listener);
    };

    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [key, handler]);
};

export default useOnKeypress;
